<template>
    <v-container class="fill-height">
        <v-row align="start" class="fill-height">
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        任务修改
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            dense
                            @change="loadTask"
                            append-icon="mdi-magnify"
                            label="搜索商品名称"
                            @click:append="loadTask"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-card-actions>
                        <v-btn color="primary" @click="goCreateTask">创建任务</v-btn>
                    </v-card-actions>
                    <v-data-table
                        :headers="headers"
                        locale="zh-CN"
                        :items="list"
                        :items-per-page="20"
                        loading-text="加载中"
                        no-data-text="暂无数据"
                        no-results-text="未查询到数据"
                        :footer-props="footerProps"
                        calculate-widths
                        :options.sync="options"
                        show-expand
                        :server-items-length="total"
                        :loading="loading">
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length"><a :href="item.goods_link" target="_blank">{{
                                    item.goods_title
                                }}</a></td>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn text small color="primary" @click="copyTask(item)">
                                复制下单
                            </v-btn>
                            <v-btn text v-if="user.enable_template" small color="primary" @click="setAsTemplate(item)">
                                存为模板
                            </v-btn>
                            <v-btn text small color="error" @click="stop(item)" v-if="item.show_stop">
                                暂停
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-overlay v-model="overlay">
            <v-progress-circular
                indeterminate
                size="56"
            ></v-progress-circular>
            保存为模板中...
        </v-overlay>
    </v-container>
</template>

<script>
import TaskApi from '../api/task.js';
import TaskTemplateApi from '../api/template.js'
import {EventBus} from "../event-bus";

export default {
    data() {
        return {
            total: 0,
            search: '',
            list: [],
            loading: true,
            options: {},
            overlay: false,
            headers: [
                {text: '商品标题', align: 'start', sortable: false, value: 'goods_title_short', },
                {text: '平台', value: 'platform_text', sortable: false, width: 68},
                {text: '发布时间', value: 'date', sortable: false, width: 200},
                {text: '关键词', value: 'keywords', sortable: false},
                {text: 'cpc', value: 'amount', sortable: false},
                {text: '备注', value: 'remark', sortable: false,},
                {text: '状态', value: 'status_text', sortable: false, width:80},
                {text: '', value: 'data-table-expand'},
                {text: '操作', value: 'actions', sortable: false, width: 168},
            ],
            footerProps: {
                "items-per-page-options": [5, 10, 15, 20, 30, 50],
                "items-per-page-text": '每页行数：'
            },
        }
    },
    watch: {
        options: {
            handler() {
                this.loadTask();
            },
            deep: true,
        },
    },
    // mounted() {
    //     this.loadTask();
    // },
    methods: {
        loadTask() {
            this.loading = true;
            let that = this;
            Object.assign(this.options, {search: this.search});
            TaskApi.listTask(this.options).then(function (resp) {
                if (resp.data.code === 200) {
                    let data = resp.data.data;
                    that.list = data.list;
                    that.total = data.total;
                }
                that.loading = false;
            });

        },
        goCreateTask() {
            this.$router.push({'name': 'home'})
        },
        copyTask(item) {
            this.$router.push({'name': 'home', params: {id: item.id}});
        },
        stop(item) {
            let that = this;
            this.$confirm('该暂停计划将于明日生效，今日计划会正常进行。确定暂停该计划吗？').then(res=> {
                if (res) {
                    TaskApi.stop(item.id).then(function (response) {
                        if (response.data.code === 200) {
                            EventBus.$emit('show-snackbar', {msg: '暂停成功', color: 'success'});
                            that.loadTask();
                        }
                    });
                }
            })
        },
        setAsTemplate(item) {
            this.overlay = true;
            TaskTemplateApi.createTemplate(item.id).then((response) => {
                this.overlay = false;
                if (response.data.code === 200) {
                    EventBus.$emit('show-snackbar', {msg: '已保存为模板', color: 'success'});
                }
            });
        }
    },
    computed: {
        user() {
            return this.$store.getters.getUser;
        },
    }
}
</script>
