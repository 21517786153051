import {JZKJ_CONFIG} from "../config";
export default {
    createTask(data) {
        return axios.post(JZKJ_CONFIG.API_URL + '/task/create', data)
    },
    listTask(params) {
        return axios.get(JZKJ_CONFIG.API_URL + '/task/list', {
            params: params
        });
    },
    stop(id) {
        return axios.post(JZKJ_CONFIG.API_URL + '/task/stop', {id: id})
    },
    taskDetail(id) {
        return axios.get(JZKJ_CONFIG.API_URL + '/task/detail', {params: {'id': id}})
    },
    batchCreateTask(data) {
        return axios.post(JZKJ_CONFIG.API_URL + '/task/batchCreate', data)
    }
}
