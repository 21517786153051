<template>
    <v-app id="inspire">
        <v-navigation-drawer v-if="!!user && userLoadStatus === 2"
                             v-model="drawer"
                             :clipped="$vuetify.breakpoint.lgAndUp"
                             :width="200"
                             app>
            <v-list nav>
                <v-subheader>菜单</v-subheader>
                <v-list-item-group v-model="activeItem" color="primary">
                    <template v-for="(item, i) in items">
                        <v-list-item
                            :key="i"
                            link
                            :to="item.to"
                        >
                            <v-list-item-action>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar dense :clipped-left="$vuetify.breakpoint.lgAndUp" app color="primary" dark>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"
                                v-if="!!user && userLoadStatus === 2"></v-app-bar-nav-icon>
            <v-toolbar-title style="width: 300px" class="ml-0 pl-4" @click="goHome">
                <span class="hidden-sm-and-down">商家后台</span>
<!--                <span class="hidden-sm-and-down">思蚁科技</span>-->
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="hidden-sm-and-down" v-if="!!user && userLoadStatus === 2" text @click="goRecharge">余额：¥{{
                balance}}
            </v-btn>
            <v-menu offset-y open-on-hover close-on-click v-if="!!user && userLoadStatus === 2">
                <template v-slot:activator="{ on }">
                    <v-btn text v-on="on">
                        {{user.name}}
                        <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        v-for="(item, index) in dropDownItems"
                        :key="index"
                        @click="handleCommand(index)">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <v-main class="fill-height">
            <v-container class="fill-height" fluid>
                <v-fade-transition>
                    <router-view class="fill-height"></router-view>
                </v-fade-transition>
            </v-container>
        </v-main>
        <v-overlay :value="logoutOverlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
            退出登录中
        </v-overlay>
        <div class="text-center ma-2">
            <v-snackbar
                v-model="snackbar"
                :color="color"
                bottom
                :timeout="6000"
            >
                {{ msg }}
                <v-btn
                    text
                    dark
                    @click="snackbar = false"
                >
                    关闭
                </v-btn>
            </v-snackbar>
        </div>
    </v-app>
</template>

<script>
    import {EventBus} from "../event-bus";

    export default {
        name: "Layout",
        data: function () {
            return {
                dialog: false,
                drawer: null,
                items: [
                    {icon: 'mdi-send', text: '任务发布', to: 'home'},
                    {icon: 'mdi-history', text: '任务修改', to: 'taskList'},
                    {icon: 'mdi-database', text: '数据查询', to: 'orderList'},
                    {icon: 'mdi-currency-usd', text: '账户充值', to:'recharge'},
                ],
                activeItem: 0,
                dropDownItems: [
                    {title: '修改密码'},
                    {title: '退出登录'},
                ],
                logoutOverlay: false,
                msg: '',
                color: 'error',
                snackbar: false,
            }
        },
        methods: {
            handleCommand(command) {
                switch (command) {
                    case 1:
                        this.logoutOverlay = true;
                        this.$store.dispatch('logoutUser');
                        window.location = '/logout';
                        break;
                    case 0:
                        this.$router.push({name: 'changePwd'});
                        break;
                    default:
                        break;
                }
            },
            goHome() {
                this.$router.push({name: 'home'}).catch(err => {
                });
            },
            goRecharge() {
                this.$router.push({name: 'recharge'}).catch(err => {
                });
            },
        },
        computed: {
            user() {
                return this.$store.getters.getUser;
            },
            userLoadStatus() {
                return this.$store.getters.getUserLoadStatus();
            },
            balance() {
                let user = this.$store.getters.getUser;
                if (user && user.user_account) {
                    return user.user_account.balance;
                } else {
                    return 0;
                }
            },
        },
        mounted() {
            EventBus.$on('prompt-login', function () {
                this.$router.push({name: 'login'});
            });
            EventBus.$on('show-snackbar', ({msg, color = 'error'}) => {
                this.color = color;
                this.msg = msg;
                this.snackbar = true;
            });
            if (!!flash_msg) {
                EventBus.$emit('show-snackbar', {msg: flash_msg});
            }
            this.$store.dispatch("loadConfig");
            if (this.user && this.user.enable_template) {
                this.items.splice(1, 0, {icon: 'mdi-folder-star', text: '模板管理', to: 'templateList'});
            }
            if (this.user && this.user.enable_service) {
                this.items.push({icon: 'mdi-face-agent', text: '联系我们', to: 'contactUs'});
            }
        },
    }
</script>

<style scoped>

</style>
