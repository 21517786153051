<template>
    <v-container class="fill-height">
        <v-row align="start" class="fill-height">
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        账单查询
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        locale="zh-CN"
                        :items="list"
                        :items-per-page="20"
                        loading-text="加载中"
                        no-data-text="暂无数据"
                        no-results-text="未查询到数据"
                        :footer-props="footerProps"
                        :options.sync="options"
                        :server-items-length="total"
                        :loading="loading"
                    >
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import FundApi from '../api/fund.js';

    export default {
        data() {
            return {
                total: 0,
                search: '',
                list: [],
                loading: true,
                options: {},
                dialog: false,
                headers: [
                    {text: '交易时间', value: 'created_at', sortable: false,},
                    {text: '交易金额', sortable: false, value: 'amount',},
                    {text: '当前余额', sortable: false, value: 'balance',},
                    {text: '消费类型', value: 'trade_type', sortable: false,},
                    {text: '备注', value: 'remark', sortable: false,},

                ],
                footerProps: {
                    "items-per-page-options": [5, 10, 15, 20, 30, 50],
                    "items-per-page-text": '每页行数: '
                },
                itemToStop: null,
            }
        },
        watch: {
            options: {
                handler() {
                    this.loadLog();
                },
                deep: true,
            },
        },
        methods: {
            loadLog() {
                this.loading = true;
                let that = this;
                Object.assign(this.options, {search: this.search});
                FundApi.listFundLog(this.options).then(function (resp) {
                    if (resp.data.code === 200) {
                        let data = resp.data.data;
                        that.list = data.list;
                        that.total = data.total;
                    }
                    that.loading = false;
                });

            },
        },
    }
</script>
