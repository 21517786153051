<template>
    <v-container class="fill-height">
        <v-row align="start" class="fill-height">
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        修改密码
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-text-field
                                label="原密码"
                                type="password"
                                v-model="form.password"
                                :rules="rules.password"
                                prepend-icon="mdi-lock"
                            ></v-text-field>
                            <v-text-field
                                label="新密码"
                                type="password"
                                :rules="rules.newPwd"
                                v-model="form.newPwd"
                                prepend-icon="mdi-lock"
                            ></v-text-field>
                            <v-text-field
                                label="确认新密码"
                                type="password"
                                :rules="rules.rNewPwd"
                                v-model="form.rNewPwd"
                                prepend-icon="mdi-lock"
                            ></v-text-field>

                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" @click="doChange" :disabled="!valid" :loading="loading">修 改</v-btn>
                    </v-card-actions>

                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import UserApi from '../api/user'
    import {EventBus} from "../event-bus";

    export default {
        name: "ChangePassword",
        data() {
            return {
                form: {
                    password: '',
                    newPwd: '',
                    rNewPwd: '',
                },
                loading: false,
                valid: true,
                rules: {
                    password: [v => !!v || '原密码必填'],
                    newPwd: [v => !!v || '新密码必填'],
                    rNewPwd: [
                        v => !!v || '确认新密码必填',
                        v => v === this.form.newPwd || '两次输入密码不一致'
                    ],
                }
            }
        },
        methods: {
            doChange() {
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    let that = this;
                    UserApi.changePwd(this.form).then(function (resp) {
                        that.loading = false;
                        if (resp.data.code === 200) {
                            EventBus.$emit('show-snackbar', {msg: '密码修改成功', color: 'success'});
                        }
                    });
                }
            },
        },
        computed: {
        }
    }
</script>

<style scoped>

</style>
