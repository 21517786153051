<template>
    <v-container class="fill-height">
        <v-row align="start" class="fill-height">
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        模板管理
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            dense
                            @change="loadTaskTemplate"
                            append-icon="mdi-magnify"
                            label="搜索商品名称"
                            @click:append="loadTaskTemplate"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-card-actions>
                        <v-btn color="primary" :disabled="selected.length === 0" @click="handleBatchCreateTask">
                            批量发布任务
                        </v-btn>
                    </v-card-actions>
                    <v-data-table
                        :headers="headers"
                        locale="zh-CN"
                        :items="list"
                        :items-per-page="20"
                        loading-text="加载中"
                        show-select
                        v-model="selected"
                        no-data-text="暂无数据"
                        no-results-text="未查询到数据"
                        :footer-props="footerProps"
                        calculate-widths
                        :options.sync="options"
                        show-expand
                        :server-items-length="total"
                        :loading="loading">
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length"><a :href="item.goods_link" target="_blank">{{
                                    item.goods_title
                                }}</a></td>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn text small color="primary" @click="handleUpdate(item)">
                                修改
                            </v-btn>
                            <v-btn text small color="error" @click="handleDelete(item)">
                                删除
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="batchDialog" width="500px">
            <v-card>
                <v-card-title>批量发布任务</v-card-title>
                <v-card-text>
                    <div>发布前请按需求修改模板的<strong>日均获取量</strong>和<strong>发布时间</strong></div>
                    <div class="mt-2">若此处选择了发布时间，则所有任务统一为此发布时间。若未选择，则以模板的发布时间为准。</div>
                    <div class="mt-2">
                        <v-menu
                            ref="menu2"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :return-value.sync="dates"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    label="统一发布时间"
                                    clearable
                                    :value="dates.join('~')"
                                    hint="为保证服务的及时性，次日的投放计划必须于当天18：00前完成"
                                    readonly
                                    @click:clear="dates=[]"
                                    prepend-icon="mdi-calendar-range"
                                    v-on="on"/>
                            </template>
                            <v-date-picker v-model="dates" no-title range locale="zh-cn" :min="minDate"
                                           @change="$refs.menu2.save(dates) ">
                            </v-date-picker>
                        </v-menu>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn @click="batchDialog = false">取消</v-btn>
                    <v-btn color="primary" :loading="batchLoading" @click="submitBatchCreate">确认发布</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" width="66%">
            <v-card>
                <v-card-title>修改模板</v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row no-gutters align="center">
                            平台：
                            <v-radio-group v-model="form.platform" row>
                                <v-radio
                                    v-for="(p, k) in platforms"
                                    :key="p.value"
                                    :value="p.value"
                                    style="margin-right: 24px"
                                >
                                    <template slot="label">
                                        <v-img :src="p.logo" max-height="30" width="40" contain></v-img>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </v-row>

                        <v-text-field
                            v-model="form.goods_link"
                            :rules="rules.linkRules"
                            label="商品链接"
                            @change="fetchTitle"
                            type="url"
                            prepend-icon="mdi-link"
                            required
                        ></v-text-field>

                        <v-text-field
                            v-model="form.goods_title" :rules="rules.titleRules"
                            label="商品标题" required
                            prepend-icon="mdi-cart"
                            hint="根据链接自动识别，如未识别，请手动填写"
                        ></v-text-field>

                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="form.dates"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    :value="dateRangeText"
                                    label="发布时间"
                                    clearable
                                    hint="为保证服务的及时性，次日的投放计划必须于当天18：00前完成"
                                    readonly
                                    :rules="rules.dateRules"
                                    @click:clear="form.dates=[];dateRangeText=''"
                                    prepend-icon="mdi-calendar-range"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="form.dates" no-title range locale="zh-cn" :min="minDate"
                                           @change="$refs.menu.save(form.dates) ">
                            </v-date-picker>
                        </v-menu>
                        <v-row no-gutters justify="center" align="center">
                            <v-col cols="5" class="center-block">
                                <v-text-field
                                    prepend-icon="mdi-counter"
                                    v-model="form.amount"
                                    label="日均获取量cpc"
                                    :rules="rules.amountRules"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="7" class="pr-2">
                                <template v-for="(keyword, i) in form.keywords">
                                    <v-row no-gutters>
                                        <v-text-field
                                            prepend-icon="mdi-key"
                                            v-model="keyword.keyword"
                                            :label="'关键词 '+(i+1)"
                                            :rules="keywordRules"
                                        >
                                            <template v-slot:append-outer
                                                      v-if="form.keywords && form.keywords.length > 3">
                                                <v-btn color="error" small @click="deleteKeyword(i)">删除</v-btn>
                                            </template>
                                        </v-text-field>
                                    </v-row>
                                </template>
                            </v-col>
                        </v-row>
                        <v-row no-gutters justify="center">
                            <v-btn @click="addKeyword" v-if="keywords.length < keywordLimit" small color="primary">
                                <v-icon small left>mdi-plus</v-icon>
                                增加关键词
                            </v-btn>
                        </v-row>
                        <v-text-field
                            prepend-icon="mdi-note-text"
                            v-model="form.remark"
                            label="备注"
                        ></v-text-field>
                        <v-row no-gutters>
                            <v-spacer/>
                            <v-btn class="mr-4"
                                   @click="handleUpdateCancel">
                                取 消
                            </v-btn>
                            <v-btn :disabled="!valid" color="success" class="mr-4"
                                   @click="validate"
                                   :loading="saveLoading"> 保 存
                            </v-btn>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import TaskApi from '../api/task.js';
import TaskTemplateApi from '../api/template.js';
import {EventBus} from "../event-bus";
import moment from "moment";
import CommonApi from "../api/common";

export default {
    data() {
        return {
            total: 0,
            search: '',
            menu2:false,
            list: [],
            loading: true,
            saveLoading: false,
            batchLoading: false,
            options: {},
            dialog: false,
            batchDialog: false,
            overlay: false,
            selected: [],
            dates: [],
            headers: [
                {
                    text: '商品标题',
                    align: 'start',
                    sortable: false,
                    value: 'goods_title_short',
                },
                {text: '发布时间', value: 'date', sortable: false,},
                {text: '关键词', value: 'keywords', sortable: false},
                {text: '日均获取量cpc', value: 'amount', sortable: false},
                {text: '备注', value: 'remark', sortable: false,},
                {text: '', value: 'data-table-expand'},
                {text: '操作', value: 'actions', sortable: false, width: 168},
            ],
            footerProps: {
                "items-per-page-options": [5, 10, 15, 20, 30, 50, 100, 150],
                "items-per-page-text": '每页行数：'
            },
            form: {
                keywords: [],
                dates: []
            },
            rules: {
                linkRules: [
                    v => !!v || '商品链接必填',
                ],
                titleRules: [
                    v => !!v || '商品标题必填',
                ],
                goodsIdRules: [
                    v => !!v || '商品编号必填',
                ],
                dateRules: [
                    v => !!v || '请选择发布时间范围',
                    v => v.indexOf('~') > 0 || '请选择起始和截止两个日期',
                    v => (v.split(' ~ ')[0] > '2024-02-16' || v.split(' ~ ')[1] < '2024-02-09') || '温馨提示：过年期间2月9日-16日暂不支持计划执行，请分时段重下计划。'
                ],
                amountRules: [
                    v => !!v || '日均获取量必填',
                    v => /^\d+$/.test(v) || '日均获取量必须为数字'
                ],

            },
            menu: false,
            valid: true,
            platforms: [
                {text: '淘宝', value: 1, 'logo': 'https://jzkjyl.oss-cn-hangzhou.aliyuncs.com/static/logo/taobao.png'},
                {text: '京东', value: 2, 'logo': 'https://jzkjyl.oss-cn-hangzhou.aliyuncs.com/static/logo/jingdong.png'},
            ],
        }
    },
    watch: {
        options: {
            handler() {
                this.loadTaskTemplate();
            },
            deep: true,
        },
    },
    mounted() {
        if (this.user.enable_pdd === 1) {
            this.platforms.push({
                text: '拼多多',
                value: 3,
                'logo': 'https://jzkjyl.oss-cn-hangzhou.aliyuncs.com/static/logo/pinduoduo.png'
            })
        }
    },
    methods: {
        loadTaskTemplate() {
            this.loading = true;
            let that = this;
            Object.assign(this.options, {search: this.search});
            TaskTemplateApi.listTemplate(this.options).then(function (resp) {
                if (resp.data.code === 200) {
                    let data = resp.data.data;
                    that.list = data.list;
                    that.total = data.total;
                }
                that.loading = false;
            });

        },
        handleDelete(item) {
            let that = this;
            this.$confirm("确认删除该模板吗", {buttonTrueText: "确认", buttonFalseText: '取消'}).then(res => {
                if (res) {
                    TaskTemplateApi.deleteTemplate(item.id).then((response) => {
                        if (response.data.code === 200) {
                            EventBus.$emit('show-snackbar', {msg: '删除成功', color: 'success'});
                            that.loadTaskTemplate();
                        }
                    })
                }
            });
        },
        handleUpdate(item) {
            TaskTemplateApi.templateDetail(item.id).then(resp => {
                if (resp.data.code === 200) {
                    const form = resp.data.data;
                    console.log(form);
                    form.keywords = form.keywords.split(',').map(item => {
                        return {keyword: item};
                    })
                    form.dates = [form.start_date, form.end_date];
                    this.form = form;
                    this.dialog = true;
                }
            });
        },
        handleBatchCreateTask() {
            this.dates = [];
            this.batchDialog = true;

        },
        submitBatchCreate() {
            this.batchLoading = true;
            let templateIds = this.selected.map(item => {
                return item.id
            });
            TaskApi.batchCreateTask({templateIds: templateIds, dates: this.dates}).then(resp => {
                if (resp.data.code === 200) {
                    EventBus.$emit('show-snackbar', {
                        msg: '任务发布成功',
                        color: 'success',
                    });
                }
                this.batchLoading = false;
                this.batchDialog = false;
            }).finally(() => {
                this.batchLoading = false;
            });
        },
        handleUpdateCancel() {
            this.reset();
            this.dialog = false;
        },
        //提交修改
        validate() {
            let that = this;
            if (this.$refs.form.validate()) {
                this.saveLoading = true;
                TaskTemplateApi.updateTemplate(this.form).then(function (resp) {
                    if (resp.data.code === 200) {
                        EventBus.$emit('show-snackbar', {
                            msg: '修改成功',
                            color: 'success',
                        });
                    }
                    that.reset();
                    that.saveLoading = false;
                    that.dialog = false;
                    that.loadTaskTemplate();
                });
            }
        },
        reset() {
            this.$refs.form.reset();
            this.form = {
                keywords: [],
                dates: []
            };
        },
        deleteKeyword(index) {
            this.form.keywords.splice(index, 1);
        },
        addKeyword() {
            this.form.keywords.push({keyword: '', amount: ''});
        },
        fetchTitle() {
            let that = this;
            if (!!this.form.link) {
                CommonApi.getTitleFromUrl({url: this.form.link, type: this.form.platform}).then(function (resp) {
                    if (resp.data.code === 200) {
                        let data = resp.data.data;
                        that.form.title = data.title;
                        that.form.goods_id = data.id;
                    }
                });
            }
        }
    },
    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        minDate() {
            return moment().add(1, 'days').format('YYYY-MM-DD');
            // if (moment().hour() >= 18) {
            //     return moment().add(2, 'days').format('YYYY-MM-DD');
            // } else {
            //     return moment().add(1, 'days').format('YYYY-MM-DD');
            // }
        },
        keywordLimit() {
            return this.$store.getters.globalConfig.keywordLimit || 20
        },
        dateRangeText() {
            if (!!this.form.dates && this.form.dates.length > 1) {
                return this.form.dates.join(' ~ ')
            } else {
                return '';
            }
        },
        keywords() {
            return this.form.keywords;
        },
        keywordRules() {
            return [
                v => !!v || '关键词必填',
                v => {
                    let i = 0;
                    let result = true;
                    for (; i < v.length; i++) {
                        let w = v.charAt(i);
                        if (w === ' ') {
                            continue;
                        }
                        if (!this.form.goods_title.toUpperCase().includes(w.toUpperCase())) {
                            result = false;
                            break;
                        }

                    }
                    return result || '关键词的每个字都必须在标题中';
                }
            ]
        },
        cost() {
            let cost = 0;
            let days = 0;
            if (this.form.dates.length === 2) {
                let start = this.form.dates[0];
                let end = this.form.dates[1];
                days = moment.duration(moment(end).diff(moment(start))).asDays() + 1;
            } else {
                return 0;
            }
            let price = this.$store.getters.getUser.user_account.price;
            cost = (this.form.amount * price * days).toFixed(2);
            return cost;
        },
        balanceEnough() {
            let balance = this.$store.getters.getUser.user_account.balance;
            return parseFloat(this.cost) <= balance;
        }
    }
}
</script>
