import CommonApi from '../api/common';

export const config = {
    state: {
        config: {},
    },
    actions: {
        loadConfig({commit}) {
            CommonApi.getConfig()
                .then(function (response) {
                    commit('setConfig', response.data.data);
                })
                .catch(function () {
                    commit('setConfig', {});
                })
        },
        clearConfig({commit}) {
            commit("setConfig", {})
        }
    },
    mutations: {
        setConfig(state, config) {
            state.config = config;
        },
    },
    getters: {
        globalConfig: state => {
            return state.config;
        },
    }
};
