import {EventBus} from "./event-bus";

window.$ = window.jQuery = require('jquery');
window.axios = require('axios').default;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
    window.axios.defaults.transformResponse = [(resp) => {
        resp = JSON.parse(resp)
        if (resp.code !== 200) {
            EventBus.$emit('show-snackbar', {msg: resp.msg});
        } else if (resp.code === 401) {
            EventBus.$emit('show-snackbar', {msg: '登录过期，请重新登录'});
            EventBus.$emit('prompt-login');
        } else {
            return resp;
        }
    }];
    window.token = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

window._ = require('lodash');

import Vue from "vue";
import router from './routes'
import store from "./store";
import vuetify from "./plugins/vuetify";

new Vue({
    router,
    store,
    vuetify
}).$mount('#app');

