import {JZKJ_CONFIG} from "../config";
export default {
    createTemplate(taskId) {
        return axios.post(JZKJ_CONFIG.API_URL + '/template/create', {taskId: taskId})
    },
    updateTemplate(data) {
        return axios.post(JZKJ_CONFIG.API_URL + '/template/update', data)
    },
    listTemplate(params) {
        return axios.get(JZKJ_CONFIG.API_URL + '/template/list', {
            params: params
        });
    },
    templateDetail(id) {
        return axios.get(JZKJ_CONFIG.API_URL + '/template/detail', {params: {'id': id}})
    },
    deleteTemplate(id) {
        return axios.post(JZKJ_CONFIG.API_URL + '/template/delete', {id: id})
    },
}
