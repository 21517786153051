<template>
    <v-container class="fill-height">
        <v-row align="start" class="fill-height">
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <span>
                            请选择充值方式：
                        </span>
                        <v-spacer></v-spacer>
                        <v-btn text @click="goLog">我的账单</v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-btn x-large color="info" block class="mt-4" @click="goAlipay">
                            <svg height="24" width="38" viewBox="0 0 448 512"><path d="M377.74 32H70.26C31.41 32 0 63.41 0 102.26v307.48C0 448.59 31.41 480 70.26 480h307.48c38.52 0 69.76-31.08 70.26-69.6c-45.96-25.62-110.59-60.34-171.6-88.44c-32.07 43.97-84.14 81-148.62 81c-70.59 0-93.73-45.3-97.04-76.37c-3.97-39.01 14.88-81.5 99.52-81.5c35.38 0 79.35 10.25 127.13 24.96c16.53-30.09 26.45-60.34 26.45-60.34h-178.2v-16.7h92.08v-31.24H88.28v-19.01h109.44V92.34h50.92v50.42h109.44v19.01H248.63v31.24h88.77s-15.21 46.62-38.35 90.92c48.93 16.7 100.01 36.04 148.62 52.74V102.26C447.83 63.57 416.43 32 377.74 32zM47.28 322.95c.99 20.17 10.25 53.73 69.93 53.73c52.07 0 92.58-39.68 117.87-72.9c-44.63-18.68-84.48-31.41-109.44-31.41c-67.45 0-79.35 33.06-78.36 50.58z" fill="currentColor"></path></svg>
                            支付宝
                        </v-btn>
                        <v-btn x-large color="warning" block class="mt-6 mb-6" @click="goTransfer">
                            <v-icon left>mdi-credit-card-outline</v-icon>对公转账
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    name: "Recharge",
    methods: {
        goAlipay() {
            this.$router.push({ 'name': 'alipay' }).catch(err => { });
        },
        goLog() {
            this.$router.push({ 'name': 'rechargeLog' }).catch(err => { });
        },
        goTransfer() {
            this.$router.push({ 'name': 'transfer' }).catch(err => { });
        },
    }
}
</script>

<style scoped>

</style>
