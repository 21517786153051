<template>
    <v-container class="fill-height">
        <v-row align="start" class="fill-height">
            <v-col cols="12">
                <v-card class="pa-4">
                    <v-card-title>
                        支付宝充值
                    </v-card-title>
                    <v-card-text>
                        <v-item-group mandatory v-model="chargeAmount">
                            <v-container>
                                <v-row>
                                    <v-col v-for="(v, k) in chargeOptions"
                                        cols="12"
                                        md="4"
                                        :key="k"
                                    >
                                        <v-item  v-slot:default="{ active, toggle }" :value="v" >
                                            <v-card
                                                :color="active ? 'primary' : 'grey'"
                                                class="d-flex align-center"
                                                dark
                                                height="72"
                                                @click="toggle"
                                            >
                                                <v-fade-transition>
                                                    <div
                                                        v-if="active"
                                                        class="headline flex-grow-1 text-center"
                                                    >
                                                        {{ v }} 元
                                                    </div>
                                                    <div
                                                        v-if="!active"
                                                        class="headline flex-grow-1 text-center"
                                                    >
                                                        {{ v }} 元
                                                    </div>
                                                </v-fade-transition>
                                            </v-card>
                                        </v-item>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="4"
                                    >
                                        <v-item v-slot:default="{ active, toggle }" value="-1">
                                            <v-card
                                                :color="active ? 'primary' : 'grey'"
                                                class="d-flex align-center"
                                                dark
                                                height="72"
                                                @click="toggle"
                                            >
                                                <v-fade-transition>
                                                    <div
                                                        v-if="active"
                                                        class="headline flex-grow-1 text-center"
                                                    >
                                                        其他金额
                                                    </div>
                                                    <div
                                                        v-if="!active"
                                                        class="headline flex-grow-1 text-center"
                                                    >
                                                        其他金额
                                                    </div>
                                                </v-fade-transition>
                                            </v-card>
                                        </v-item>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-item-group>

                        <v-row v-if="showCustomMoney">
                            <v-col cols="12" md="12">
                                <v-form ref="form" v-model="valid" lazy-validation>
                                    <v-text-field
                                        prepend-icon="mdi-currency-usd"
                                        v-model="customAmount"
                                        :rules="customAmountRule"
                                        label="其他金额"
                                        suffix="元"
                                    ></v-text-field>
                                </v-form>
                            </v-col>
                        </v-row>

                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="success" class="ml-4" large @click="toPay">立即充值</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PayApi from '../api/pay.js';
import {EventBus} from "../event-bus";

export default {
    name: "Alipay",
    data: () => ({
        valid: true,
        chargeAmount: 0,
        customAmount: '',
        customAmountRule: [
            v => !!v || '充值金额必填',
            v => /^\d+$/.test(v) || '充值金额必须为数字',
            v => parseInt(v) >= 10000 || '充值金额不得低于 10000 元'
        ],
    }),
    methods: {
        toPay() {
            let money = this.chargeAmount;
            if (parseInt(this.chargeAmount) === -1) {
                if (this.$refs.form.validate()) {
                    money = this.customAmount;
                    PayApi.preparePay(money).then(function (resp) {
                        if (resp.data.code === 200) {
                            window.location.href = resp.data.data;
                        }
                    });
                }
            } else {
                PayApi.preparePay(money).then(function (resp) {
                    if (resp.data.code === 200) {
                        window.location.href = resp.data.data;
                    }
                });
            }
        },
    },
    computed: {
        showTestAmount() {
            return this.$store.getters.getUser.id === 1;
        },
        showCustomMoney() {
            return parseInt(this.chargeAmount) === -1;
        },
        chargeOptions() {
            return this.$store.getters.globalConfig.alipayOptions
        }
    },
    mounted() {
        let trade_status = this.$route.query.out_trade_no;
        if (!!trade_status) {
            //支付成功
            EventBus.$emit('show-snackbar', {msg: '支付成功，到账可能有延时，请稍后刷新查看余额变化', color: 'success'});
        }
    }
}
</script>

<style scoped>

</style>
