<template>
    <v-container class="fill-height">
        <v-row align="start" class="fill-height">
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        数据查询
                        <v-spacer></v-spacer>
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="filter.putDate"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="showPutDate"
                                    label="发布时间"
                                    clearable
                                    @click:clear="clearDate"
                                    hint="选择发布时间范围进行筛选"
                                    readonly
                                    prepend-icon="mdi-calendar-range"
                                    single-line
                                    hide-details
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filter.putDate" no-title range locale="zh-cn">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu = false">取消</v-btn>
                                <v-btn text color="primary" @click="rangeFilter">确认</v-btn>
                            </v-date-picker>
                        </v-menu>
                        &nbsp;&nbsp;
                        <v-text-field
                            v-model="filter.search"
                            @change="loadOrder"
                            clearable
                            append-icon="mdi-magnify"
                            label="搜索商品编号或关键词"
                            @click:append="loadOrder"
                            single-line
                            hide-details
                        ></v-text-field>
                        &nbsp;&nbsp;
                        <v-btn @click="exportData" :disabled="exportDisabled" color="primary">
                            导出
                        </v-btn>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        locale="zh-CN"
                        :items="list"
                        :items-per-page="20"
                        loading-text="加载中"
                        no-data-text="暂无数据"
                        no-results-text="未查询到数据"
                        :footer-props="footerProps"
                        :options.sync="options"
                        :server-items-length="total"
                        :loading="loading"
                    >
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import OrderApi from '../api/order.js';
import {JZKJ_CONFIG} from "../config";

export default {
    data() {
        return {
            total: 0,
            filter: {
                search: '',
                putDateStart: '',
                putDateEnd: '',
                putDate: []
            },
            menu: false,
            list: [],
            loading: true,
            options: {},
            dialog: false,
            exportDisabled:false,
            headers: [
                {text: '平台', sortable: false, value: 'platform',},
                {text: '商品编号', sortable: false, value: 'goods_no',},
                {text: '投放时间', value: 'put_date', sortable: false,},
                {text: '关键词', value: 'keyword', sortable: false},
                {text: '完成量', value: 'finish_count', sortable: false,},
                {text: '消费金额', value: 'cost', sortable: false,},
            ],
            footerProps: {
                "items-per-page-options": [5, 10, 15, 20, 30, 50],
                "items-per-page-text": '每页行数: '
            },
            itemToStop: null,
        }
    },
    watch: {
        options: {
            handler() {
                this.loadOrder();
            },
            deep: true,
        },
    },
    methods: {
        loadOrder() {
            this.loading = true;
            let that = this;
            if (this.filter.putDate.length > 0) {
                this.filter.putDateStart = this.filter.putDate[0];
                this.filter.putDateEnd = this.filter.putDate[1];
            }
            Object.assign(this.options, this.filter);
            OrderApi.listOrder(this.options).then(function (resp) {
                if (resp.data.code === 200) {
                    let data = resp.data.data;
                    that.list = data.list;
                    that.total = data.total;
                }
                that.loading = false;
            });

        },
        rangeFilter() {
            this.$refs.menu.save(this.filter.putDate);
            this.loadOrder()
        },
        clearDate() {
            this.filter.putDate = [];
            this.filter.putDateStart = '';
            this.filter.putDateEnd = '';
            this.$refs.menu.save(this.filter.putDate);
            this.loadOrder()
        },
        exportData() {
            if (this.filter.putDate.length > 0) {
                this.filter.putDateStart = this.filter.putDate[0];
                this.filter.putDateEnd = this.filter.putDate[1];
            }
            Object.assign(this.options, this.filter);
            this.options.laravel_token = window.token;
            this.loading = true;
            this.exportDisabled = true;
            let that = this
            OrderApi.exportOrder(this.options).then(function (resp) {
                that.exportDisabled = false;
                that.loading = false;
                if (resp.data.code === 200) {
                    let url = resp.data.data;
                    window.location.href = url;
                }
            });
        }
    },
    computed: {
        showPutDate() {
            if (!!this.filter.putDate) {
                return this.filter.putDate.join(' 至 ')
            } else {
                return '';
            }
        },
    }
}
</script>
