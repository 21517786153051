<template>
    <v-card
        class="mx-auto"
        max-width="320"
        max-height="376"
    >
        <v-img
            :src="user.service_qrcode"
            width="320px"
            contain
        ></v-img>

        <v-card-subtitle class="text-center">
            请扫描上方微信二维码联系您的专属顾问
        </v-card-subtitle>
    </v-card>
</template>

<script>
export default {
    name: "ContactUs",
    computed: {
        user() {
            return this.$store.getters.getUser;
        },
    },
}
</script>

<style scoped>

</style>
