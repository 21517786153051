<template>
    <v-container class="fill-height">
        <v-row align="start" class="fill-height">
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        银行转账充值
                    </v-card-title>
                    <v-card-text>
                        <p>
                            转账前请咨询客服确认金额，转账后请及时联系客服，客服将在后台给您的账户充值。
                        </p>
                        <p>转账银行账户信息如下</p>
                        <p>
                            公司名：杭州思蚁科技有限公司
                        </p>
                        <p>
                            对公账户：3301040160017325377
                        </p>
                        <p>
                            开户行：杭州银行滨江支行
                        </p>

                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        微信/支付宝扫码充值
                    </v-card-title>
                    <v-card-text>
                        <p>转账前请咨询客服确认金额，转账后请及时联系客服，客服将在后台给您的账户充值。</p>
                        <v-img width="300"
                            src="https://cooetech.oss-cn-hangzhou.aliyuncs.com/public/alipay_qrcode.png"></v-img>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "OfflineTransfer"
}
</script>

<style scoped>

</style>
