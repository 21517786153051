import {JZKJ_CONFIG} from "../config";

export default {
    getTitleFromUrl: function (params) {
        return axios.get(JZKJ_CONFIG.API_URL + '/url/title', {
            params: params,
        });
    },
    getConfig: function () {
        return axios.get(JZKJ_CONFIG.API_URL + '/config');
    }
}
