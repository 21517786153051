
require('es6-promise').polyfill();

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import {user} from "./modules/user";
import {config} from "./modules/config";

export default new Vuex.Store({
    modules: {
        user,config
    }
});
