import Vue from 'vue';
import Vuetify from "vuetify";
import VuetifyConfirm from 'vuetify-confirm';
import  'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'font-awesome/css/font-awesome.min.css'

Vue.use(Vuetify);

const opts = {
    theme: {
        themes: {
            light:   {
                primary: '#009688',
                secondary: '#e91e63',
                accent: '#ff9800',
                error: '#f44336',
                warning: '#ffc107',
                info: '#00bcd4',
                success: '#4caf50'
            },
        },
    },
};
const vuetify =  new Vuetify(opts);
Vue.use(VuetifyConfirm, {
    vuetify,
    buttonTrueText: '确认',
    buttonFalseText: '取消',
    color: 'warning',
    icon:'mdi-info',
    title: '提示',
    width: 350
});

export default vuetify;
