import {JZKJ_CONFIG} from "../config";

export default {
    listOrder: function (params) {
        return axios.get(JZKJ_CONFIG.API_URL + '/order/list', {
            params: params
        });
    },
    exportOrder: function (params) {
        return axios.get(JZKJ_CONFIG.API_URL + '/order/export', {
            params: params,
        });
    },
}
