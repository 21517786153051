<template>
    <v-container class="fill-height">
        <v-img
            :src="newBg"
            style="height: 100%;width: 100%;position: absolute;top: 0;left: 0;bottom:0; background-size: cover"
        >
        </v-img>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
                <v-card class="elevation-12" style="background: rgba(255,255,255,0.7)">
                    <v-toolbar color="primary" dark flat style="opacity: 0.7">
                        <v-toolbar-title>登录</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="form" action="/login" method="POST">
                            <v-text-field v-model="form.name" label="用户名" name="name" prepend-icon="mdi-account"
                                          type="text"></v-text-field>
                            <v-text-field v-model="form.password" id="password" label="密码" name="password"
                                          prepend-icon="mdi-lock" type="password"></v-text-field>
                            <input type="hidden" v-model="form.token" name="_token"></input>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="onSubmit" :loading="loading">登 录</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-footer class="pa-1" padless style="background: rgba(255,255,255,0.7)" absolute>
            <v-spacer></v-spacer>
            <span class="text--secondary caption"> &copy; {{ new Date().getFullYear() }} <a
                href="http://beian.miit.gov.cn" target="_blank">浙ICP备2021007074号</a></span>
            <v-spacer></v-spacer>
        </v-footer>
    </v-container>
</template>

<script>
    import UserApi from '../api/user';

    export default {
        name: "Login",
        data() {
            return {
                form: {
                    name: '',
                    password: '',
                    token: token,
                },
                loading: false,
                newBg: '',
                originBg: '',
            }
        },
        mounted() {
            this.getUrl();
        },
        methods: {
            onSubmit() {
                this.loading = true;
                this.$refs.form.$el.submit();
            },
            getUrl() {
                let that = this;
                UserApi.getBg().then(function (resp) {
                    console.log(resp);
                    that.newBg = resp.data.data;
                });
            }
        },
        computed:{
            bgHeight(){
                return this.$vuetify.breakpoint.height - 147;
            }
        }
    }
</script>

<style scoped>
</style>
