import {JZKJ_CONFIG} from "../config";

export default {
    login: function (params) {
        return axios.post(JZKJ_CONFIG.API_URL + '/login', params)
    },
    getBg: function () {
        return axios.get(JZKJ_CONFIG.API_URL + '/bg');
    },
    getUser: function () {
        return axios.get(JZKJ_CONFIG.API_URL + '/user')
    },
    changePwd: function (params) {
        return axios.post(JZKJ_CONFIG.API_URL + '/user/changePwd', params)
    },
}
