<template>
    <v-container class="fill-height">
        <v-row justify="start" class="fill-height">
            <v-col cols="12">
                <v-card>
                    <v-card-title>任务发布</v-card-title>
                    <v-card-text>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-row no-gutters align="center">
                                平台：
                                <v-radio-group v-model="form.platform" row>
                                    <v-radio
                                        v-for="(p, k) in platforms"
                                        :key="p.value"
                                        :value="p.value"
                                        style="margin-right: 24px"
                                    >
                                        <template slot="label">
                                            <v-img :src="p.logo" max-height="30" width="40" contain></v-img>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                            </v-row>

                            <v-text-field
                                v-model="form.link"
                                :rules="rules.linkRules"
                                label="商品链接"
                                @change="fetchTitle"
                                type="url"
                                prepend-icon="mdi-link"
                                required
                            ></v-text-field>

                            <v-text-field
                                v-model="form.title" :rules="rules.titleRules"
                                label="商品标题" required
                                prepend-icon="mdi-cart"
                                hint="根据链接自动识别，如未识别，请手动填写"
                            ></v-text-field>

                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="form.dates"
                                transition="scale-transition"
                                offset-y
                                min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        :value="dateRangeText"
                                        label="发布时间"
                                        clearable
                                        hint="为保证服务的及时性，次日的投放计划必须于当天18：00前完成"
                                        readonly
                                        :rules="rules.dateRules"
                                        @click:clear="form.dates=[];dateRangeText=''"
                                        prepend-icon="mdi-calendar-range"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.dates" no-title range locale="zh-cn" :min="minDate" @change="$refs.menu.save(form.dates) ">
                                </v-date-picker>
                            </v-menu>
                            <v-row no-gutters justify="center" align="center">
                                <v-col cols="5" class="center-block">
                                    <v-text-field
                                        prepend-icon="mdi-counter"
                                        v-model="form.amount"
                                        label="日均获取量cpc"
                                        :rules="rules.amountRules"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="7" class="pr-2">
                                    <template v-for="(keyword, i) in form.keywords">
                                        <v-row no-gutters>
                                            <v-text-field
                                                prepend-icon="mdi-key"
                                                v-model="keyword.keyword"
                                                :label="'关键词 '+(i+1)"
                                                :rules="keywordRules"
                                            >
                                                <template v-slot:append-outer v-if="form.keywords.length > 1">
                                                    <v-btn color="error" small @click="deleteKeyword(i)">删除</v-btn>
                                                </template>
                                            </v-text-field>
                                        </v-row>
                                    </template>
                                </v-col>
                            </v-row>
                            <v-row no-gutters justify="center">
                                <v-btn @click="addKeyword" v-if="keywords.length < keywordLimit" small color="primary">
                                    <v-icon small left>mdi-plus</v-icon>
                                    增加关键词
                                </v-btn>
                            </v-row>
                            <v-text-field
                                prepend-icon="mdi-note-text"
                                v-model="form.remark"
                                label="备注"
                            ></v-text-field>
                            <v-row no-gutters>
                                <span>
                                    <v-btn :disabled="!valid || !balanceEnough" color="success" class="mr-4"
                                           @click="validate"
                                           :loading="loading"> 确认下单
                                    </v-btn>
                                    此单预计消耗：{{cost}} 元。
                                </span>
                                <span v-if="!balanceEnough">
                                    余额不足：<v-btn text color="info" @click="goCharge">去充值</v-btn></span>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import CommonApi from '../api/common';
    import {EventBus} from '../event-bus';
    import TaskApi from '../api/task';

    let moment = require('moment');

    export default {
        data: () => ({
            id: null,
            valid: true,
            platforms: [
                {text: '淘宝', value: 1, 'logo' : 'https://jzkjyl.oss-cn-hangzhou.aliyuncs.com/static/logo/taobao.png'},
                {text: '京东', value: 2, 'logo': 'https://jzkjyl.oss-cn-hangzhou.aliyuncs.com/static/logo/jingdong.png'},
                // {text: '拼多多', value: 3, 'logo': 'https://jzkjyl.oss-cn-hangzhou.aliyuncs.com/static/logo/pinduoduo.png'},
            ],
            form: {
                link: '',
                title: '',
                dates: [],
                platform: 1,
                amount: '',
                keywords: [
                    {keyword: '', amount: '',},
                ],
                remark: '',
            },
            rules: {
                linkRules: [
                    v => !!v || '商品链接必填',
                ],
                titleRules: [
                    v => !!v || '商品标题必填',
                ],
                goodsIdRules: [
                    v => !!v || '商品编号必填',
                ],
                dateRules: [
                    v => !!v || '请选择发布时间范围',
                    v => v.indexOf('~') > 0 || '请选择起始和截止两个日期',
                    v => (v.split(' ~ ')[0] > '2024-02-16' || v.split(' ~ ')[1] < '2024-02-09') || '温馨提示：过年期间2月9日-16日暂不支持计划执行，请分时段重下计划。'
                ],
                amountRules: [
                    v => !!v || '日均获取量必填',
                    v => /^\d+$/.test(v) || '日均获取量必须为数字'
                ],

            },
            menu: false,
            loading: false,
        }),
        mounted() {
            const id = this.$route.params.id
            if (id) {
                let that = this;
                TaskApi.taskDetail(id).then(function (resp) {
                    if (resp.data.code === 200) {
                        that.form = resp.data.data;
                    }
                });
            }
            if (this.user.enable_pdd === 1) {
                this.platforms.push({text: '拼多多', value: 3, 'logo': 'https://jzkjyl.oss-cn-hangzhou.aliyuncs.com/static/logo/pinduoduo.png'})
            }

        },
        watch: {
            'form.dates': function () {
                let dates = this.form.dates;
                if (dates.length === 2) {
                    if (dates[0] > dates[1]) {
                        this.form.dates = [dates[1], dates[0]];
                    }
                }
            },
        },

        methods: {
            validate() {
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    let that = this;
                    TaskApi.createTask(this.form).then(function (resp) {
                        if (resp.data.code === 200) {
                            EventBus.$emit('show-snackbar', {
                                msg: '下单成功',
                                color: 'success',
                            });
                            that.reset();
                        }
                        that.loading = false;
                    }).finally(() => {
                        that.loading = false;
                    });
                }
            },
            reset() {
                this.$refs.form.reset();
                this.form = {
                    link: '',
                    title: '',
                    dates: [],
                    platform: 1,
                    amount: '',
                    keywords: [
                        {keyword: '', amount: '',},
                    ],
                    remark: '',
                };
                this.platform = 0;
            },
            deleteKeyword(index) {
                this.form.keywords.splice(index, 1);
            },
            addKeyword() {
                this.form.keywords.push({keyword: '', amount: ''});
            },
            fetchTitle() {
                let that = this;
                if (!!this.form.link) {
                    CommonApi.getTitleFromUrl({url: this.form.link, type: this.form.platform}).then(function (resp) {
                        if (resp.data.code === 200) {
                            let data = resp.data.data;
                            that.form.title = data.title;
                            that.form.goods_id = data.id;
                        }
                    });
                }
            },
            goCharge() {
                this.$router.push({'name': 'recharge'}).catch(err => {
                });
            },
        },
        computed: {
            user() {
                return this.$store.getters.getUser;
            },
            minDate() {
                return moment().add(1, 'days').format('YYYY-MM-DD');
                // if (moment().hour() >= 18) {
                //     return moment().add(2, 'days').format('YYYY-MM-DD');
                // } else {
                //     return moment().add(1, 'days').format('YYYY-MM-DD');
                // }
            },
            keywordLimit() {
                return this.$store.getters.globalConfig.keywordLimit || 20
            },
            dateRangeText() {
                if (!!this.form.dates && this.form.dates.length > 1) {
                    return this.form.dates.join(' ~ ')
                } else {
                    return '';
                }
            },
            keywords() {
                return this.form.keywords;
            },
            keywordRules() {
                return [
                    v => !!v || '关键词必填',
                    v => {
                        let i = 0;
                        let result = true;
                        for (; i < v.length; i++) {
                            let w = v.charAt(i);
                            if (w === ' ') {
                                continue;
                            }
                            if (!this.form.title.toUpperCase().includes(w.toUpperCase())) {
                                result = false;
                                break;
                            }

                        }
                        return result || '关键词的每个字都必须在标题中';
                    },
                    v => {
                        let result = this.keywords.filter(row => row.keyword == v).length <= 1
                        return result || '关键词不允许重复'
                    }
                ]
            },
            cost() {
                let cost = 0;
                let days = 0;
                if (this.form.dates.length === 2) {
                    let start = this.form.dates[0];
                    let end = this.form.dates[1];
                    days = moment.duration(moment(end).diff(moment(start))).asDays() + 1;
                } else {
                    return 0;
                }
                let price = this.$store.getters.getUser.user_account.price;
                cost = (this.form.amount * price * days).toFixed(2);
                return cost;
            },
            balanceEnough() {
                let balance = this.$store.getters.getUser.user_account.balance;
                return parseFloat(this.cost) <= balance;
            }
        }
    }
</script>
