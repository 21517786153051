/*
 |-------------------------------------------------------------------------------
 | routes.js
 |-------------------------------------------------------------------------------
 | Contains all of the routes for the application
 */

/**
 * Imports Vue and VueRouter to extend with the routes.
 */
import Vue from 'vue';
import VueRouter from 'vue-router';

/**
 * Extends Vue to use Vue Router
 */
Vue.use(VueRouter);
import store from "./store";

function requireAuth(to, from, next) {
    function proceed() {
        // 如果用户信息已经加载并且不为空则说明该用户已登录，可以继续访问路由，否则跳转到首页
        // 这个功能类似 Laravel 中的 auth 中间件
        if (store.getters.getUserLoadStatus() === 2) {
            if (!!store.getters.getUser) {
                next();
            } else {
                next('/login');
            }
        }
    }

    if (store.getters.getUserLoadStatus() !== 2) {
        // 如果用户信息未加载完毕则先加载
        store.dispatch('loadUser');

        // 监听用户信息加载状态，加载完成后调用 proceed 方法继续后续操作
        store.watch(store.getters.getUserLoadStatus, function () {
            if (store.getters.getUserLoadStatus() === 2) {
                proceed();
            }
        });
    } else {
        // 如果用户信息加载完毕直接调用 proceed 方法
        proceed()
    }
}

const Layout = Vue.component('Layout', require('./layout/Layout')).default;
const Login = Vue.component('Login',require('./pages/Login.vue')).default;
const Home = Vue.component('Home', require('./pages/Home.vue')).default;
const ChangePassword = Vue.component('ChangePassword', require('./pages/ChangePassword.vue')).default;
const Recharge = Vue.component('Recharge', require('./pages/Recharge.vue')).default;
const TaskList = Vue.component('TaskList', require('./pages/TaskList.vue')).default;
const TemplateList = Vue.component('TemplateList', require('./pages/TemplateList.vue')).default;
const OrderList = Vue.component('OrderList', require('./pages/OrderList.vue')).default;
const OfflineTransfer = Vue.component('OfflineTransfer', require('./pages/OfflineTransfer.vue')).default;
const Alipay = Vue.component('Alipay', require('./pages/Alipay.vue')).default;
const RechargeLog = Vue.component('RechargeLog', require('./pages/RechargeLog.vue')).default;
const ContactUs = Vue.component('ContactUs', require('./pages/ContactUs.vue')).default;

export default new VueRouter({
    routes: [
        {
            path: '/',
            name: 'layout',
            redirect: {name: 'home'},
            component: Layout,
            children: [
                {
                    path: '/home',
                    name: 'home',
                    component: Home,
                    beforeEnter: requireAuth,
                },
                {
                    path: '/login',
                    name: 'login',
                    component: Login,
                },
                {
                    path: '/changePwd',
                    name: 'changePwd',
                    component: ChangePassword,
                    beforeEnter: requireAuth,
                },
                {
                    path: '/orderList',
                    name: 'orderList',
                    component: OrderList,
                    beforeEnter: requireAuth,
                },
                {
                    path: '/recharge',
                    name: 'recharge',
                    component: Recharge,
                    beforeEnter: requireAuth,
                },
                {
                    path: '/taskList',
                    name: 'taskList',
                    component: TaskList,
                    beforeEnter: requireAuth,
                },
                {
                    path: '/templateList',
                    name: 'templateList',
                    component: TemplateList,
                    beforeEnter: requireAuth,
                },
                {
                    path: '/transfer',
                    name: 'transfer',
                    component: OfflineTransfer,
                    beforeEnter: requireAuth,
                },
                {
                    path: '/alipay',
                    name: 'alipay',
                    component: Alipay,
                    beforeEnter: requireAuth,
                },
                {
                    path: '/rechargelog',
                    name: 'rechargeLog',
                    component: RechargeLog,
                    beforeEnter: requireAuth,
                },
                {
                    path: '/contactUs',
                    name: 'contactUs',
                    component: ContactUs,
                    beforeEnter: requireAuth,
                },
            ]
        },
    ]
});
