import UserApi from '../api/user';

export const user = {
    state: {
        user: {},
        userLoadStatus: 0,
        loginStatus: 0,
    },
    actions: {
        login({commit}, params) {
            commit('setUserLoadStatus', 1);
            commit('setLoginStatus', 1);
            UserApi.login(params)
                .then(function (response) {
                    commit('setUser', response.data.data);
                    commit('setLoginStatus', 2);
                    commit('setUserLoadStatus', 2);
                })
                .catch(function () {
                    commit('setUser', {});
                    commit('setLoginStatus', 3);
                    commit('setUserLoadStatus', 3);
                })
        },
        loadUser({commit}, data) {
            commit('setUserLoadStatus', 1);
            UserApi.getUser()
                .then(function (resp) {
                    commit('setUser', resp.data.data);
                    commit('setUserLoadStatus', 2);
                })
                .catch(function () {
                    commit('setUser', {});
                    commit('setUserLoadStatus', 3);
                })
        },
        logoutUser({commit}) {
            commit('setUserLoadStatus', 0);
            commit('setUser', {});
        }
    },
    mutations: {
        setUserLoadStatus(state, status) {
            state.userLoadStatus = status;
        },
        setUser(state, user) {
            state.user = user;
        },
        setLoginStatus(state, status) {
            state.loginStatus = status;
        }
    },
    getters: {
        getUserLoadStatus(state) {
            return function() {
                return state.userLoadStatus;
            }
        },
        getUser(state) {
            return state.user;
        },
        getLoginStatus(state) {
            return state.loginStatus;
        }
    }
};
